export const PSLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 395.87 305.62">
      <path d="M254.49,133.33" transform="translate(-401.06 -246.69)" fill="transparent" />
      <path
        d="M550.77,246.69c4.48,1.65,9.22,2.22,13.83,3.31,18.29,4.31,36.35,9.43,54.3,15,12.49,3.85,25,7.73,37,13,11.3,4.94,21.94,10.93,31.18,19.28,11.42,10.34,18,23.26,21.12,38.16a153.66,153.66,0,0,1,2.51,20.08c.86,11.77.32,23.39-2.59,34.81-2.28,8.93-6.21,17.13-13,23.61a36.82,36.82,0,0,1-24.75,10.35c-10.22.45-19.7-2.19-29-6.06-1.85-.77-2.37-1.87-2.37-3.82.06-32.46.13-64.93,0-97.39,0-6.35-1.4-12.64-6.83-16.93-3.39-2.68-7.28-4.76-11.69-2.71-4,1.83-5.4,5.66-6.32,9.65a26.77,26.77,0,0,0-.53,5.93q0,119.7,0,239.38h-.48c-8.85-2.87-17.69-5.78-26.55-8.6Q568.84,538,551,532.51c-1.24-.39-2-1-1.72-2.36a2.57,2.57,0,0,0,0-.48q0-141.49,0-283Z"
        transform="translate(-401.06 -246.69)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
      <path
        d="M796.93,473.62c-2.56,8.37-9.24,12.78-16.19,17s-14.85,7-22.57,9.78q-29.92,10.78-59.86,21.53c-10.95,3.94-21.92,7.82-32.88,11.74-12.6,4.51-25.23,9-37.78,13.64-2.06.76-2,0-2-1.53q.06-16.56,0-33.11a2.6,2.6,0,0,1,2.08-2.91Q657,499.43,686.28,489q22.07-7.87,44.11-15.84a18,18,0,0,0,8-5c1.47-1.75,1.5-3.13,0-4.83-2.48-2.75-5.91-3.46-9.26-4-12.77-2.06-25.12-.32-37.23,4.14-12.8,4.7-25.73,9-38.6,13.54-8.42,3-16.85,5.9-25.25,8.92-1.77.63-2.48.6-2.46-1.63.1-11.44.08-22.87,0-34.31a2.4,2.4,0,0,1,2-2.68,221.3,221.3,0,0,1,38-9.18c7.46-1.17,14.93-2,22.49-2.49a161.45,161.45,0,0,1,17.45-.27c16.95.77,33.72,2.93,50,7.8,8.71,2.59,17.41,5.3,25.54,9.43,4.9,2.5,9.56,5.45,12.84,10.1a26.26,26.26,0,0,1,3,6.65Z"
        transform="translate(-401.06 -246.69)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
      <path
        d="M401.06,479.86c2-8,8.37-11.83,14.84-15.58,9.57-5.55,19.84-9.47,30.22-13.15q34.26-12.15,68.48-24.4c6.76-2.4,13.56-4.7,20.31-7.13,1.23-.44,1.49-.25,1.49,1q0,17.26,0,34.54c0,1.25-.61,1.64-1.58,2-5.4,1.94-10.79,3.92-16.19,5.85-16.66,6-33.33,11.89-50,17.94a19.07,19.07,0,0,0-6.6,3.8c-3.41,3.18-2.28,6,1.68,8a28.06,28.06,0,0,0,9.91,2.46,72.63,72.63,0,0,0,31.23-3.65c9.62-3.4,19.18-7,28.75-10.51,2.56-.94,2.73-.87,2.73,1.76,0,9.44,0,18.87,0,28.31,0,1.71-.19,2.67-2.33,3-7.93,1.21-15.85,2.25-23.87,2.84-6.55.48-13.08.39-19.62.33a187.44,187.44,0,0,1-29.61-3,228.68,228.68,0,0,1-34.84-9.06c-7.76-2.62-15-6.14-20.61-12.46-2.24-2.52-3.3-5.56-4.46-8.59Z"
        transform="translate(-401.06 -246.69)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
      <path
        d="M773,521.59c7.9-.36,14.89,6.7,14.92,15.07,0,6.71-5.49,15.33-15,15.05-9.19-.26-14.9-6-15.17-15.17A14.69,14.69,0,0,1,773,521.59Z"
        transform="translate(-401.06 -246.69)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
      <path
        d="M772.46,549c-5.7.55-12.48-5.51-12.08-12.65.37-6.68,5.74-12.33,12.4-12.3s12.7,6,12.61,12.48C785.28,543.78,779.18,549.42,772.46,549Z"
        transform="translate(-401.06 -246.69)"
        fill="#000"
      />
      <path
        d="M766.22,536.14c0-2.07.08-4.16,0-6.23-.07-1.51.37-2.19,2-2.1,2.16.11,4.32,0,6.48,0,4.42.14,6.39,3.73,4.25,7.66-.15.27-.47.69-.39.8,1.5,2,.84,4.48,1.33,6.7.13.57,1.25,1.37-.15,1.52-1,.1-2.57.89-2.9-1a24.85,24.85,0,0,1-.06-2.87c-.1-1.74-.75-2.77-2.78-2.78-4.73,0-4.75-.1-4.87,4.74,0,1.1,0,2.09-1.56,2s-1.32-1.08-1.32-2C766.21,540.46,766.22,538.3,766.22,536.14Z"
        transform="translate(-401.06 -246.69)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
      <path
        d="M772.16,535c-1-.38-3,1-3.1-1.76,0-1.93.42-2.86,2.5-2.59a21.11,21.11,0,0,0,2.87,0c1.42,0,2.3.64,2.32,2.12s-.82,2.17-2.25,2.2Z"
        transform="translate(-401.06 -246.69)"
        fill="#000"
      />
    </svg>
  );
};
