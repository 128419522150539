export const EpicLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2696 3129">
      <path
        d="M1371.66,3129h-46c-39.22-1.27-72.3-21.07-107.08-35.64q-251.64-105.43-503.3-210.84-252.94-106.2-506-212.12c-38.06-16-76.91-30.35-113.69-49.23-53.37-27.4-87-69.3-92.35-130.8-.87-9.92-.84-19.9-1.56-29.85,0-8.49.16-17-2-25.3V211.93c3-17.31,1.19-35,4.19-52.32C11.18,117.5,25.6,78.91,58,48.83c24.2-22.45,53.23-34.59,84.6-42.46C160.15,2,178.12,2.13,196,1c5.89-.38,11.89,1.09,17.68-1h2266c6.36,2.23,12.92.43,19.36,1,37.73,0,73.9,6.43,107.79,24.12,41.55,21.68,65.94,56.53,79.71,100.18,6,18.93,7.41,38.35,8.19,58,.17,4.23-1.17,8.6.95,12.69V2451.22c-1.95,17.45-.5,35.13-3.11,52.5-5.15,34.33-19.16,64.07-45.91,87.27-29.49,25.57-64.61,40.72-100,55.51q-251.39,105-502.56,210.42-248.55,104-497,208.14c-31.35,13.11-63,25.51-93.75,40.08C1427.38,3117.43,1400.88,3127.91,1371.66,3129Z"
        transform="translate(0.34)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
      <path
        d="M3.61,2461.21c-3.17-2.6-.7-7.42-3.95-10v-16C3.66,2443.48,3.88,2452.31,3.61,2461.21Z"
        transform="translate(0.34)"
        fill="rgb(208, 210, 209)"
      />
      <path
        d="M2499.66,0c0,.63,0,1.26.06,1.88-6.7-.48-13.66,1.86-20.06-1.88Z"
        transform="translate(0.34)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
      <path
        d="M1765.51,1015q0-190.41,0-380.83c0-30.74,2.15-61.2,10.11-91,18.56-69.57,63.31-112.19,133-129.11a300.53,300.53,0,0,1,71.28-8.32q52,0,104,0c27.79,0,55.19,3.32,81.91,11,62.87,18.2,102.6,59.28,119.84,122.25,7.53,27.48,10.41,55.57,10.41,84q0,119.44,0,238.89c0,1.67-.09,3.34,0,5,.18,3.21-.89,5.09-4.48,4.82-2-.14-4,0-6,0h-158a25.4,25.4,0,0,0-4,0c-5.68.92-6.9-2.11-6.48-6.92.2-2.32,0-4.67,0-7q0-108,0-215.9a140.47,140.47,0,0,0-2.79-29.77c-5.2-24.09-19.83-39.29-44-43.15-25.4-4.06-51.22-4.5-76.57.53s-39.84,21.3-43.81,46.9a161.44,161.44,0,0,0-1.94,24.88q.07,374.32,0,748.67a167.19,167.19,0,0,0,1.8,23.89c3.77,26.37,18.77,43.12,45.1,47.9,25.37,4.6,51.3,4.22,76.64.62,29.59-4.21,45.25-23.35,48-54,.48-5.29.49-10.65.49-16q0-128.94,0-257.89c0-12.23-1.6-11,11.38-11q78.49-.06,157,0c2,0,4,.12,6,0,3.75-.27,5.66,1.14,5.27,5.11-.13,1.32,0,2.67,0,4-.05,93.62.07,187.25-.28,280.87a288.44,288.44,0,0,1-11.12,78.87c-18.69,65.2-61.11,106-126.43,123.38-26.24,7-53,9.82-80.09,9.88-35.66.09-71.32.25-107-.2a286.83,286.83,0,0,1-76.86-11.22c-64.83-18.84-105-61.47-122.32-126.27-8-29.84-10.11-60.3-10.1-91Q1765.59,1205.88,1765.51,1015Z"
        transform="translate(0.34)"
      />
      <path
        d="M896,1015.7q0-293.87,0-587.72c0-13.87-1.91-12.12,12.2-12.13q139.47-.08,279,0c29.12,0,57.79,3.36,85.82,11.59,63.16,18.54,102.84,60,120.7,123,8.63,30.41,10.87,61.56,10.9,92.93q.15,154.42,0,308.85c0,30.69-2.22,61.14-10.32,91-18.86,69.59-63.82,111.82-133.5,128.51a307.57,307.57,0,0,1-72.3,8.18q-48.49,0-97,0c-13.71,0-12.42-2.17-12.43,11.95q-.08,77,0,153.93v259.87c0,10.61.52,9.76-9.69,9.76h-164c-1.33,0-2.67-.09-4,0-3.68.3-5.69-1-5.41-5,.19-2.65,0-5.33,0-8Z"
        transform="translate(0.34)"
      />
      <path
        d="M398.68,1015.71V428c0-12.2,0-12.2,12.16-12.2q191.45,0,382.91,0c12.56,0,11.27-1.5,11.28,11.11q.08,72,0,143.92c0,2-.16,4,0,6,.37,4-1.51,5.4-5.25,5.21-2.33-.12-4.67,0-7,0h-199c-2.34,0-4.68.13-7,0-3.7-.25-5.69,1.05-5.4,5.05.15,2,0,4,0,6V909.91c0,2,.11,4,0,6-.24,3.71,1.11,5.68,5.12,5.39,2.32-.16,4.66,0,7,0h193c10.6.08,9.69-1.07,9.7,9.61q0,73.47,0,146.92c0,1.67-.09,3.34,0,5,.17,3.24-1,5-4.56,4.8-2.32-.15-4.66,0-7,0h-191c-2.33,0-4.68.15-7,0-3.77-.29-5.62,1.12-5.27,5.08.17,2,0,4,0,6v338.81c0,1,0,2,0,3,.11,8.55.11,8.63,8.81,8.63q103,0,206,.05c13.81,0,11.93-2,12,12.3q.12,71,0,141.93c0,2.66-.08,5.33,0,8,.06,3-1.37,4.16-4.25,4.07-2.67-.09-5.34,0-8,0q-192.47,0-384.91,0c-13.76,0-12.36,2.2-12.36-12Q398.66,1309.55,398.68,1015.71Z"
        transform="translate(0.34)"
      />
      <path
        d="M1486.68,1014.52V427.92c0-12.09,0-12.09,12.27-12.09h159c2.66,0,5.33,0,8,0,2.44,0,3.6,1.29,3.57,3.72s0,4.67,0,7q0,589.11,0,1178.21c0,1,0,2,0,3-.14,7.48-.15,7.61-7.74,7.61q-83.48.06-166.94,0c-8,0-8-.11-8.11-8.29,0-2.66,0-5.33,0-8Z"
        transform="translate(0.34)"
      />
      <path
        d="M1410.79,2048l54.19-87.45c10-16.13,20.15-32.15,29.83-48.46,2.52-4.24,5.65-4.78,9.87-4.77,29,.09,58,0,87,.07,7.87,0,8,.11,8,8.34q0,84.48,0,168.94V2258.6c0,2.33-.08,4.66,0,7,.11,3-1.33,4.19-4.2,4.15-3.67,0-7.33,0-11,0q-36,0-72,0c-8.53,0-8.6-.1-8.6-8.78q0-95.47,0-190.94v-10.5l-1.89-.47-93.21,143.3-92.45-142.91c-2.64,3.88-1.58,7.58-1.59,11q-.12,94-.08,187.93c0,13,1.44,11.34-11,11.38-24.66.08-49.33,0-74,0-11.12,0-9.64-.12-9.64-9.79q0-136,0-271.91c0-24.33,0-48.65,0-73,0-7.58.12-7.71,7.62-7.72,29.33,0,58.66.1,88-.13,5,0,7.61,1.88,10.13,6q40.1,65.6,80.6,131C1407.19,2045.53,1407.62,2047.35,1410.79,2048Z"
        transform="translate(0.34)"
      />
      <path
        d="M833.35,2699.58H1869.29c-1.88,3.32-5.11,3.22-7.76,4.1q-256.69,84.74-513.35,169.55a22.42,22.42,0,0,1-15.37-.28Q1113.56,2797.38,894.24,2722q-26.4-9.09-52.82-18.17C839,2703,836.25,2702.67,833.35,2699.58Z"
        transform="translate(0.34)"
      />
      <path
        d="M732,1952.28l-56.9,69.12c-9.25-6.47-17.83-13.16-27.22-18.63-27.22-15.85-56.61-20.94-86.59-12.31-37.25,10.73-58.59,37.82-66.72,75.1-6.19,28.37-2.58,55.91,12.56,80.91,20.61,34,52.27,47.94,91.15,46.54,16.75-.61,33-4.1,48-12.2,4.9-2.64,7.28-5.78,6.94-11.72-.56-9.63-.1-19.31-.17-29,0-8.46-.11-8.52-8.94-8.54-18.33,0-36.66,0-55,0-8.75,0-8.82-.08-8.84-8.56q-.07-28,0-56c0-7.9.13-8.08,7.38-8.09,50-.05,100,0,150-.13,5.32,0,6.71,1.73,6.7,6.82q-.24,74.46,0,148.92c0,5.49-2.41,8.41-6.38,11.36-37.62,28-78.9,46.15-126.3,49.94-28.92,2.32-57.41,1.22-85-7.31-65.1-20.11-109.54-61.58-127.1-128.61-12.54-47.87-8.1-94.59,14.68-138.76,18.91-36.68,47.63-63.73,85-81.52,34.21-16.29,70.21-21.06,107.86-18.58,46.19,3.06,86.57,19.4,122,48.83C729.81,1950.54,730.61,1951.15,732,1952.28Z"
        transform="translate(0.34)"
      />
      <path
        d="M1672.68,2088.59V1920.72c0-2.33.17-4.68,0-7-.37-4.47.92-7.08,6-6.42a47.84,47.84,0,0,0,6,0q131.44,0,262.89,0c13.62,0,11.84-1.57,11.9,11.4.09,20.65,0,41.3,0,62,0,9.45.89,8.9-9.44,8.9H1809.1c-11.33,0-22.66-.07-34,0-7.24.06-7.39.15-7.44,6.93-.1,14.65-.07,29.31,0,44,0,8.23.1,8.34,8.09,8.36q35,.07,70,0,43.49,0,87,0c7.1,0,7.24.14,7.27,7q.11,31.47,0,62.95c0,7.17-.15,7.27-8,7.28q-49.49.06-99,0c-19.32,0-38.65-.06-58,0-7.19,0-7.33.2-7.37,7.08q-.14,23.49,0,47c0,7.21.14,7.3,7.94,7.32,20,.06,40,0,60,0q59,0,118,0c8.14,0,8.24.1,8.26,8.09q.08,33,0,66c0,8-.1,8.11-8.16,8.12q-90.47,0-180.92,0-46,0-92,0c-8,0-8.06-.12-8.06-8.29Q1672.66,2175,1672.68,2088.59Z"
        transform="translate(0.34)"
      />
      <path
        d="M1177.91,2269.76h-61.45c-10.66,0-21.32-.2-32,.09-5,.14-7.57-1.41-9.44-6.43-6.88-18.36-14.55-36.42-21.54-54.74-1.9-5-4.07-7.32-9.92-7.28q-64,.42-127.92,0c-5.75,0-8.11,2-10,7.06-7.06,18.64-14.68,37.08-21.86,55.68-1.52,3.92-3.47,5.75-7.93,5.72-30.32-.19-60.63-.12-90.94-.18a16.38,16.38,0,0,1-3.46-1c6.68-15.81,13.25-31.35,19.81-46.9l78.65-186.54q22.65-53.74,45.31-107.5c2.45-5.82,4.69-11.73,6.95-17.62,1.31-3.38,3.18-5.25,7.3-5.22,27.31.17,54.63.21,81.94,0,4.76,0,6.35,2.42,7.83,6,8.92,21.49,17.81,43,26.86,64.43q34.34,81.32,68.77,162.57c17.93,42.58,35.64,85.24,53.44,127.87C1178.67,2266.57,1179.45,2267.42,1177.91,2269.76Z"
        transform="translate(0.34)"
      />
      <path
        d="M1995.92,2216c16.68-19.88,33.44-39.7,50-59.69,3-3.66,5.24-3.74,9-.92,16.25,12.31,33.72,22.51,53,29.47a171.4,171.4,0,0,0,59.61,10.46,77,77,0,0,0,23.51-4.2c9.15-3,15.32-9.14,16.82-19.15,1.62-10.74-2.13-19.05-11.54-24.44a124.35,124.35,0,0,0-31.51-12.26c-19-4.84-38.12-9.3-57-14.54-17.33-4.81-34.07-11.18-49.71-20.36-29.81-17.5-45-43.56-46.09-77.81-1-32.38,8.19-61.13,31.87-84.19,16.89-16.45,37.25-26.83,60.19-31.53,64.29-13.19,124.32-2.93,179.28,33.78,2.49,1.66,4.71,3.74,7.25,5.31,3.84,2.37,3.42,4.76,1,8.08-8.73,12.16-17.25,24.48-25.82,36.76-6.26,9-12.49,17.94-19,27.29-15.6-10.25-31.05-19.38-48-25.52-20.84-7.56-42.11-12.44-64.51-9-6,.92-11.63,2.79-16.46,6.65-12.64,10.1-11.92,28.58,1.72,37.36,13.39,8.61,28.91,11.61,43.95,15.73,19.88,5.44,40.08,9.71,59.64,16.3,17.47,5.89,34,13.59,48.48,25.12,26.83,21.45,35.68,50.37,33.38,83.5-1.21,17.44-5.41,34-14.69,49.21-14.56,23.81-35.64,39.18-61.66,48-40.56,13.76-81.89,13.09-123.31,5-37.16-7.23-71-22.07-100.64-46-2.34-1.88-4.64-3.8-6.87-5.8A14.88,14.88,0,0,1,1995.92,2216Z"
        transform="translate(0.34)"
      />
      <path
        d="M1079.06,797.16V589.33c0-2.33.15-4.67,0-7-.28-3.78,1.2-5.33,5.12-5.27,27.64.4,55.33-1.15,82.93.82,32.67,2.34,51.77,23.13,54.33,57.94.3,4,.38,8,.38,12q0,149.87,0,299.75a127.15,127.15,0,0,1-2,23.83c-5,26.51-22.35,42.88-49.25,46.26a228.4,228.4,0,0,1-29.93,1.33c-17.32-.09-34.65,0-52,0-10.06,0-9.58.56-9.57-10q0-55.46,0-110.9Z"
        transform="translate(0.34)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
      <path
        d="M979.29,2020.92c6.28,11.76,38.66,95,40.36,103.55H938.08C951.78,2090,965.26,2056.16,979.29,2020.92Z"
        transform="translate(0.34)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
    </svg>
  );
};
