import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PageProvider } from './PageContext';
import Home from './pages/Home';
import News from './pages/News';
import Slug from './pages/...slug';
import Faq from './pages/Faq';
import NotFound from './pages/NotFound';

import './styles/App.scss';

const App: React.FC = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <PageProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about/" element={<Home />} />
          <Route path="/media/" element={<Home />} />
          <Route path="/news/" element={<News />} />
          <Route path="/faq/" element={<Faq />} />
          <Route path="/news/:slug/" element={<Slug />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </PageProvider>
    </Router>
  );
};

export default App;
