import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Footer from '../components/Footer';
import { usePageProvider } from '../PageContext';
import { useEffect, useRef } from 'react';
import { Header } from '../components/Header';
import imagesLoaded from 'imagesloaded';
import ScrollAnimation from '../tools/ScrollAnimation';

gsap.registerPlugin(ScrollTrigger);

interface SocialMessage {
  name: string;
  link: string;
  icon: string;
  position: string;
}

interface MenuMessage {
  title: string;
  url: string;
  attr_title: string;
  classes: any;
  target: string;
}

const NotFound: React.FC = () => {
  const InitEffect = useRef(false);
  const { content, pathname } = usePageProvider();

  const headerProps: {
    header: MenuMessage[];
    social: SocialMessage[];
    isReady: boolean;
  } = {
    header: content.header,
    social: content.social,
    isReady: content.isReady,
  };

  const footerProps: {
    footer: MenuMessage[];
    esrb: string;
    legal: string;
    isReady: boolean;
  } = {
    footer: content.footer,
    esrb: content.esrb,
    legal: content.legal,
    isReady: content.isReady,
  };

  useEffect(() => {
    const ScrollController = new ScrollAnimation();
    const startProcess = () => {
      const imgLoad = imagesLoaded('#not-page', { background: true });
      imgLoad.on('done', () => {
        // Handle Inview Area
        ScrollController.ScrollAnim({
          element: 'header',
          duration: '100%',
          trigger: 0.7,
          start: 'active',
        });
        ScrollController.ScrollAnim({
          element: '#content-page',
          duration: '100%',
          trigger: 0.7,
          toggle: true,
        });
      });
    };
    const Init = () => {
      window.scrollTo(0, 0);
      try {
        startProcess();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (process.env.NODE_ENV === 'development') {
      if (InitEffect.current) {
        const cleanup = Init();
        return cleanup;
      }
      return () => {
        InitEffect.current = true;
      };
    } else {
      Init();
    }
  }, [pathname]);

  return (
    <>
      <div id="notfound-page">
        <Header path={pathname} data={headerProps} />
        <div id="content-page" className="container-xl h-100 d-flex justify-content-center align-items-center">
          <h1 className="inview animated delay-1">Page Not Found</h1>
        </div>
        <Footer data={footerProps} />
      </div>
    </>
  );
};

export default NotFound;
