import React, { createContext, useState, useContext, useEffect } from 'react';
import WPClass from './tools/WPClass';
import { useLocation } from 'react-router-dom';

interface SocialMessage {
  link: string;
  name: string;
  icon: string;
  position: string;
}
interface MenuMessage {
  url: string;
  title: string;
  attr_title: string;
  classes: any;
  target: string;
}

interface DataState {
  header: MenuMessage[];
  footer: MenuMessage[];
  social: SocialMessage[];
  esrb: '';
  legal: '';
  isReady: boolean;
}

interface PageContextType {
  content: DataState;
  pathname: string;
}

interface CommonData {
  header: string;
  footer: string;
  options: string;
}

const PageContext = createContext<PageContextType | undefined>(undefined);
const endpoints: CommonData = {
  header: 'wp-custom/v1/menus/5',
  footer: 'wp-custom/v1/menus/6',
  options: 'acfoptions/v1/options',
};

interface PageProviderProps {
  children: React.ReactNode;
}

export const PageProvider: React.FC<PageProviderProps> = ({ children }) => {
  const location = useLocation();
  const [pathname, setPathName] = useState<string>('/');
  const [content, setContent] = useState<DataState>({
    header: [],
    footer: [],
    social: [],
    esrb: '',
    legal: '',
    isReady: false,
  });

  useEffect(() => {
    setPathName(location.pathname);
    const storedData = sessionStorage.getItem(`content`);
    if (storedData) {
      setContent(JSON.parse(storedData));
    } else {
      // Call the makeRequest method
      const wpInstance = new WPClass();
      wpInstance
        .makeRequestPromise(endpoints)
        .then(responseData => {
          // Update state with the response data
          const respondsObj = {
            header: responseData.header,
            footer: responseData.footer,
            social: responseData.options.social,
            esrb: responseData.options.esrb.url,
            legal: responseData.options.legal,
            isReady: true,
          };
          setContent(respondsObj);
          sessionStorage.setItem(`content`, JSON.stringify(respondsObj));
        })
        .catch(error => {
          // Handle any errors
          console.error(error);
        });
    }
  }, [location.pathname]);

  return <PageContext.Provider value={{ content, pathname }}>{children}</PageContext.Provider>;
};

export const usePageProvider = () => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('usePageProvider must be used within a PageProvider');
  }
  return context;
};
