import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Footer from '../components/Footer';
import { usePageProvider } from '../PageContext';
import { useEffect, useRef, useState } from 'react';
import { Header } from '../components/Header';
import imagesLoaded from 'imagesloaded';
import ScrollAnimation from '../tools/ScrollAnimation';
import { FetchPageData } from '../tools/Helpers';

import HeroSideBar from '../assets/hero_sidebars.png';
import CenterDividerSvg from '../assets/center_divider_gray.svg';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const altTitle = 'Killing Floor 3 - Join the fight against unhumanity';

gsap.registerPlugin(ScrollTrigger);

interface SocialMessage {
  name: string;
  link: string;
  icon: string;
  position: string;
}

interface MenuMessage {
  title: string;
  url: string;
  attr_title: string;
  classes: any;
  target: string;
}

interface PageState {
  content: any;
}

const Slug: React.FC = () => {
  const InitEffect = useRef(false);
  const location = useLocation();
  const { content, pathname } = usePageProvider();
  const [pageData, setPageData] = useState<PageState>({ content: {} });

  const headerProps: {
    header: MenuMessage[];
    social: SocialMessage[];
    isReady: boolean;
  } = {
    header: content.header,
    social: content.social,
    isReady: content.isReady,
  };

  const footerProps: {
    footer: MenuMessage[];
    esrb: string;
    legal: string;
    isReady: boolean;
  } = {
    footer: content.footer,
    esrb: content.esrb,
    legal: content.legal,
    isReady: content.isReady,
  };

  useEffect(() => {
    const ScrollController = new ScrollAnimation();
    const fetchData = async () => {
      try {
        const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
        const lastSegment = pathSegments[pathSegments.length - 1];
        const responds = await FetchPageData(`page-${lastSegment}`, `wp/v2/news?slug=${lastSegment}`);
        if (responds && responds.content) {
          setPageData(responds);
          startProcess();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const startProcess = () => {
      const imgLoad = imagesLoaded('#news-page', { background: true });
      imgLoad.on('done', () => {
        // Handle Inview Area
        ScrollController.ScrollAnim({
          element: 'header',
          duration: '100%',
          trigger: 0.7,
          start: 'active',
        });
      });
    };
    const Init = () => {
      window.scrollTo(0, 0);
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (process.env.NODE_ENV === 'development') {
      if (InitEffect.current) {
        const cleanup = Init();
        return cleanup;
      }
      return () => {
        InitEffect.current = true;
      };
    } else {
      Init();
    }
  }, [pathname]);

  return (
    <>
      <div id="slug-page">
        <Header path={pathname} data={headerProps} />
        <div className="bars-wrapper">
          <img className="sidebar" src={HeroSideBar} alt={altTitle} />
          <img className="sidebar" src={HeroSideBar} alt={altTitle} />
        </div>
        {pageData && pageData.content && (
          <>
            <div className="header-image">
              {pageData.content.featured_image_url && (
                <img className="img-fluid w-100" src={pageData.content.featured_image_url} alt={altTitle} />
              )}
            </div>
            <div id="content-post" className="container-xl">
              <div className="date">{pageData.content.date_friendly}</div>
              {pageData.content.title && <h1>{pageData.content.title.rendered}</h1>}
              <div className="center-bar text-center my-4">
                <img className="img-fluid" src={CenterDividerSvg} alt={altTitle} />
              </div>
              {pageData.content.content && (
                <div
                  className="content-block"
                  dangerouslySetInnerHTML={{
                    __html: pageData.content.content.rendered,
                  }}
                />
              )}
              {pageData.content.related_posts && pageData.content.related_posts.length > 0 && (
                <>
                  <div className="center-bar text-center mb-4">
                    <img className="img-fluid" src={CenterDividerSvg} alt={altTitle} />
                  </div>
                  <div id="related" className="mb-5 pt-2">
                    <div className="row">
                      {pageData.content.related_posts.map((post: any, index: number) => (
                        <div className="col-md-4 mb-3" key={index}>
                          <div className="card h-100">
                            <Link to={`${post.link}`}>
                              {post.thumb && (
                                <div className="card-image">
                                  <img src={post.thumb} className="img-fluid" alt={post.title} />
                                </div>
                              )}
                            </Link>
                            <div className="card-body">
                              <div className="date">{post.date}</div>
                              <Link to={`${post.link}`}>
                                <h4>{post.title}</h4>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        <Footer data={footerProps} />
      </div>
    </>
  );
};

export default Slug;
