import WPClass from './WPClass';

export const wordTrim = (sentence: string, maxLength: number) => {
  if (sentence.length >= maxLength) {
    return sentence.substring(0, maxLength) + '...';
  }
  return sentence;
};

export const sanitizeToSlug = (title: string) => {
  // Remove leading and trailing whitespace
  let sanitizedTitle = title.trim();
  // Replace spaces with hyphens
  sanitizedTitle = sanitizedTitle.replace(/\s+/g, '-');
  // Convert to lowercase
  sanitizedTitle = sanitizedTitle.toLowerCase();
  // Remove special characters (optional)
  sanitizedTitle = sanitizedTitle.replace(/[^\w-]+/g, '');
  return sanitizedTitle;
};

export const ScrollToView = (sectionId: string, e?: Event) => {
  if (e) e.preventDefault();
  let cleanSectionId = sectionId.toLowerCase();
  const section = document.getElementById(cleanSectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
  return false;
};

export const SetCookie = (cname: string) => {
  const d = new Date();
  d.setTime(d.getTime() + 60 * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=accept;' + expires + ';path=/';
};

export const GetCookie = (cname: string) => {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const FetchPageData = async ($page: string, $endpoint: string, $single: boolean = true) => {
  const storedData = sessionStorage.getItem($page);
  if (storedData) {
    return JSON.parse(storedData);
  } else {
    // Call the makeRequest method
    const wpInstance = new WPClass();
    try {
      const responseData = await wpInstance.makeRequest($endpoint, $single);
      // Update state with the response data
      const respondsObj = {
        content: responseData,
        isReady: true,
      };
      sessionStorage.setItem($page, JSON.stringify(respondsObj));
      return respondsObj; // Return the response object
    } catch (error) {
      // Handle any errors
      console.error(error);
      return null; // Return null or some default value if there is an error
    }
  }
};
