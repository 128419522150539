import ScrollMagic from 'scrollmagic';

interface ScrollAnimParams {
  element: string;
  trigger: number;
  duration: string;
  target?: string;
  offset?: number;
  toggle?: boolean;
  pin?: string;
  switch?: {
    target: string;
    class: string;
  };
  start?: string;
}

class ScrollAnimation {
  private ScrollController;

  constructor() {
    this.ScrollController = new ScrollMagic.Controller();
  }

  // Method to Handle all Scroll Animation
  ScrollAnim(json: ScrollAnimParams) {
    const Scrolls = new ScrollMagic.Scene({
      triggerElement: json.element,
      triggerHook: json.trigger,
      duration: json.duration,
    }).addTo(this.ScrollController);

    if (json.toggle) {
      const elements = document.querySelectorAll(`${json.element} .inview`);
      Scrolls.on('enter', function (e) {
        for (var i = 0; i < elements.length; ++i) {
          var setAnimation = (elements[i] as HTMLElement).dataset.ease || 'fadeInUp';
          (elements[i] as HTMLElement).classList.add(setAnimation);
        }
      });
    }
    if (json.pin) {
      Scrolls.setPin(json.element);
    }
    if (json.offset) {
      Scrolls.offset(json.offset);
    }
    if (json.switch) {
      const elementId = document.getElementById(json.switch.target);
      Scrolls.on('update', function (self: any) {
        if (elementId && json.switch) {
          if (self && self.scrollPos > 400) {
            (elementId as HTMLElement).classList.add(json.switch.class);
          } else {
            (elementId as HTMLElement).classList.remove(json.switch.class);
          }
        }
      });
    }
    if (json.start) {
      const elementIdStart = document.getElementById(json.element);
      Scrolls.on('start', function () {
        if (elementIdStart) {
          (elementIdStart as HTMLElement).classList.add('active');
        }
      });
    }
  }
}

export default ScrollAnimation;
